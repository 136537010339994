@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-semibold leading-none text-gray-800;
  }
  h2 {
    @apply text-xl md:text-2xl 3xl:text-3xl text-gray-700;
  }
  h3 {
    @apply portrait:text-base portrait:sm:text-lg text-xl md:text-xl 2xl:text-3xl text-gray-600;
  }
  h5 {
    @apply text-2xl font-bold tracking-tight text-gray-900;
  }
  p {
    @apply text-base portrait:text-sm text-gray-600;
  }
  .p-lg {
    @apply 2xl:text-xl md:text-xl;
  }
  .p-xl {
    @apply xl:text-3xl md:text-xl;
  }
  .page-padding {
    @apply px-2 md:px-16 3xl:px-48;
  }
  .no-page-padding {
    @apply mx-[-0.5rem] md:mx-[-4rem] 3xl:-mx-48;
  }
  /* a {
    @apply block;
  } */
  .padding-y {
    @apply py-4 md:py-8 lg:py-12 xl:py-16;
  }
  .padding-b {
    @apply pb-4 md:pb-8 lg:pb-12;
  }
  .padding-t {
    @apply pt-4 md:pt-8 lg:pt-12;
  }
  .hover-transition {
    @apply transition duration-200 ease-in-out;
  }

  .page-container {
    @apply 2xl:w-[60%] xl:w-[75%] md:w-[80%] w-[90%];
  }
  .blog-container {
    @apply 2xl:w-[40%] xl:w-[50%] md:w-[60%] w-[80%];
  }
  .responsive-grid {
    @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5;
  }
  .vertical-line {
    background: linear-gradient(#488fd3, #488fd3) no-repeat center/4px 100%;
  }
  .horizontal-line {
    background: linear-gradient(
      to bottom,
      transparent 48%,
      #f76f3a 48%,
      #ee4933 52%,
      transparent 52%
    );
  }
  .line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -10;
    background: linear-gradient(
      to right,
      transparent 30%,
      #f76f3a 48%,
      #ee4933 52%,
      transparent 70%
    );
    height: 2px; /* Adjust the height of the line as needed */
    width: 100%; /* Adjust the width of the clipped line */
  }
  ul {
    list-style: disc;
    list-style-position: inside;
  }
  .border-gradient-tr-blue-green {
    border-image: linear-gradient(to right top, #34d1dc, #4461be);
    border-image-slice: 1;
  }
  .border-gradient-tr-orange-red {
    border-image: linear-gradient(to right top, #dc8034, rgb(173, 71, 68));
    border-image-slice: 1;
    border-radius: 12px;
    border: 5p solidx;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
  /* transition: 0.2s drop-shadow ease; */
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  width: 8px;
}
table::-webkit-scrollbar {
  height: 5px;
  width: 5px; /* Adjust this value to set the desired width */
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
  border-radius: 2rem;
}
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&family=PT+Serif:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&family=PT+Serif:wght@400;700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "helvetica";
  src: local("helvetica"),
    url(../public/assets/fonts/Helvetica.ttf) format("ttf");
}
@font-face {
  font-family: "helvetica-oblique";
  src: local("helvetica-oblique"),
    url(../public/assets/fonts/Helvetica-Oblique.ttf) format("ttf");
}
.main-bg {
  background-repeat: no-repeapt;
}
h1,
h2,
h3,
h4 {
  /* font-family: "Roboto", sans-serif; */
  /* color: #071c48; */
  font-family: "Playfair Display", serif;
}
body {
  font-family: "Roboto", sans-serif;
}
.pattern-container {
  background-image: url("assets/bg-pattern.png"); /* Replace with the path to your PNG pattern image */
  background-repeat: repeat; /* Repeat the pattern */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.pattern-container-2 {
  background-image: url("assets/pattern-gray.png"); /* Replace with the path to your PNG pattern image */
  background-repeat: repeat; /* Repeat the pattern */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
table {
  width: 100%;
}
tr,
td {
  /* border: 1px solid #000; */
  text-align: center;
  padding: 0.5rem 2rem;
}
.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
img {
  object-fit: cover;
}
.bg-primary {
  background-color: #233f65 !important;
}
.color-primary {
  color: #233f65;
}
.color-secondary {
  color: #ffac0a;
}
.button {
  cursor: pointer;
}
body {
  background-color: #fafafa;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.slick-next::before,
.slick-prev::before {
  color: #ffac0a !important;
}
.gradient2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
}
#our-doctors .slick-list {
  /* overflow: initial !important; */
}
.bg-footer {
  background-color: #223f66;
}

/* the parent */
.slick-list {
  margin: 0 -15px;
}
/* item */
.item {
  padding: 0 15px;
}
.slick-prev:before,
.slick-next:before {
  font-size: 40px !important;
  z-index: 100;
  color: #071c48 !important;
}
.slick-prev,
.slick-next {
  height: 40px !important;
  width: 40px !important;
  z-index: 10;
}
.our-services-icon,
.choose-us-icon {
  filter: invert(21%) sepia(16%) saturate(2237%) hue-rotate(175deg)
    brightness(94%) contrast(89%);
}
.footer-icon {
  filter: invert(99%) sepia(85%) saturate(102%) hue-rotate(324deg)
    brightness(119%) contrast(100%);
}
#homepage-slider .slick-slide,
#maincard-slider .slick-slide,
/* #doctor-slider .slick-slide, */
#success-slider .slick-slide,
#blog-slider .slick-slide {
  height: 100% !important;
}
.our-patients {
  background-image: url("assets/border2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.slick-dots {
  bottom: -40px !important;
}
.slick-dots li button:before {
  font-size: 16px !important;
  color: #ddb795 !important;
}
.slick-dots li.slick-active button:before {
  color: #e37513 !important;
}
#homepage-slider .slick-slider,
#maincard-slider .slick-slider,
#blog-slider .slick-slider,
#success-slider .slick-slider,
#mission-slider .slick-slider,
#mission-slider .slick-list,
#homepage-slider .slick-list,
#blog-slider .slick-list,
#success-slider .slick-list,
#maincard-slider .slick-list {
  height: 100%;
  width: 100%;
}
#mission-slider .slick-track,
#homepage-slider .slick-track,
#maincard-slider .slick-track {
  height: 100%;
}
/* #mission-slider .slick-slide > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

#homepage-slider .slick-slide > div,
#maincard-slider .slick-slide > div,
#mission-slider .slick-slide > div,
#doctor-slider .slick-slide > div {
  width: 100%;
  height: 100%;
  min-height: 100%;
}
.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}
.slick-slide > div {
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .slick-prev:before,
  .slick-next:before,
  .slick-prev,
  .slick-next {
    height: 15px !important;
    font-size: 20px !important;

    width: 15px !important;
    /* display: none !important; */
  }
  .slick-prev {
    left: -5px !important;
  }
  .slick-next {
    right: -5px !important;
  }
  .slick-dots {
    bottom:-10px !important
  }
  .slick-dots li button:before {
    font-size: 8px !important;
  }
}
.icon-primary {
  filter: invert(74%) sepia(32%) saturate(5185%) hue-rotate(182deg)
    brightness(88%) contrast(91%);
}
.gradient-text-instagram {
  background-image: linear-gradient(
    to right,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #fd1d1d
  );
  -webkit-background-clip: text;
  color: transparent;
}
.circle {
  float: left;
  width: 100px;
  height: 100px;
  shape-outside: circle();
  clip-path: circle();
}
